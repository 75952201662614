import React from "react";
import { SelectBox } from "../";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { actions } from "atp-rule-binder";
import { useSelector, useDispatch } from "react-redux";

function AddFactSection(props) {
  const disPatch = useDispatch();
  const collection_list = useSelector(
    (state) => state.ruleMastersSlice.collection_list
  );
  const { stateName, setActions, vl, actionsList, index = 0, editData } = props;
  const [attrList, setAttrList] = React.useState([]);
  let entityName = vl?.in?.label ?? null;
  React.useEffect(() => {
    const intialCall = async () => {
      if (entityName && attrList.length === 0 && editData) {
        let list = await disPatch(actions.ATTRIBUTE_LIST({ name: entityName }));
        setAttrList(list.payload.data);
      }
    };
    intialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityName]);
  const stateUpdate = async (name, value) => {
    const data = JSON.parse(JSON.stringify(actionsList));
    data[index][name] = value;
    setActions(stateName, data);
    if (name === "in") {
      let list = await disPatch(
        actions.ATTRIBUTE_LIST({
          name: value?.name,
          db: localStorage.getItem("dbname"),
        })
      );
      setAttrList(list.payload.data);
    }
  };
  const removeRow = async () => {
    const data = JSON.parse(JSON.stringify(actionsList));
    data.splice(index, 1);
    setActions(stateName, data);
  };
  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: 10 }}>
        <Grid md={3} style={{ paddingRight: 15 }}>
          <Typography>name</Typography>
          <TextField
            inputProps={{ style: { height: 12 } }}
            value={vl.name}
            variant="outlined"
            size="small"
            onChange={(e, value) => {
              stateUpdate("name", e.target.value);
            }}
          />
        </Grid>
        <Grid md={4} style={{ paddingRight: 15, marginTop: 18 }}>
          <SelectBox
            withText="In"
            list={collection_list?.data ?? []}
            value={vl.in ? vl.in : null}
            onchange={(e, value) => {
              stateUpdate("in", value);
            }}
          />
        </Grid>
        <Grid md={4} style={{ paddingRight: 15, marginTop: 18 }}>
          <SelectBox
            list={attrList ?? []}
            value={vl.if ? vl.if : null}
            onchange={(e, value) => {
              stateUpdate("if", value);
            }}
            withText="If"
          />
        </Grid>
        {vl?.if?.columnType === "dimension" && (
          <DimensionField
            opt={vl?.if?.fields ?? []}
            fieldName={vl?.if?.columnName ?? ""}
            onchange={stateUpdate}
            vl={vl}
            value={vl["dimension" + (vl?.if?.columnName ?? "")]}
          />
        )}
        {index !== 0 && (
          <Grid
            md={1}
            style={{ display: "flex", paddingRight: 15, marginTop: 18 }}
          >
            <img
              onClick={() => removeRow()}
              alt=""
              src="/images/trash.svg"
              style={{ margin: "auto" }}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

function DimensionField({ opt, value, fieldName, onchange, vl }) {
  let currentName = "dimension" + fieldName;
  let nextName = "dimension" + value?.columnName;
  return (
    <React.Fragment>
      <Grid md={4} style={{ paddingRight: 15, marginTop: 18 }}>
        <SelectBox
          list={opt ?? []}
          value={value ? value : null}
          Optionlabel={"columnName"}
          fieldName={nextName}
          onchange={(e, value) => {
            onchange(currentName, value);
          }}
        />
      </Grid>
      {value?.columnType === "dimension" && (
        <DimensionField
          opt={value?.fields ?? []}
          fieldName={value?.columnName ?? ""}
          onchange={onchange}
          vl={vl}
          value={vl["dimension" + (value?.columnName ?? "")]}
        />
      )}
    </React.Fragment>
  );
}

function AddFact(props) {
  const { actionsList, setActions, stateName } = props;
  const addNewRow = () => {
    const data = JSON.parse(JSON.stringify(actionsList));
    data.push({ name: null, attr: null, type: null });
    setActions(stateName, data);
  };
  return (
    <React.Fragment>
      <Button
        color="primary"
        style={{ float: "right", marginTop: "-22px" }}
        onClick={() => addNewRow()}
      >
        + Add New
      </Button>
      {actionsList.map((vl, i) => {
        return <AddFactSection index={i} {...props} vl={vl} />;
      })}
    </React.Fragment>
  );
}

export default AddFact;

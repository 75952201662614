import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import PrivateRoute from "./private_router";
import { Login, AddRule, Home } from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* redirect page component(login) */}
        {/* <Route exact path="/">
          {sessionStorage.getItem("isLogin") ? (
            <Redirect to={routes.home} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path={routes.login} component={Login} /> */}

        <PrivateRoute path={"/"} component={Home} />
        <PrivateRoute
          path={routes.rules}
          tokenvalidation={true}
          component={Home}
        />

        <PrivateRoute path={routes.addrule} component={AddRule} />
      </Switch>
    </Router>
  );
};

export default RouterApp;

import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import routes from "./routes";

const PrivateRoute = (props) => {
  const { children, tokenvalidation, ...rest } = props;
  // React.useEffect(()=>{

  // })
  const isAuthenticated = () => {
    // let params = new URLSearchParams(props.location.search).get("token")
    // if (sessionStorage.getItem('isLogin') || (tokenvalidation && params)) {
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  };

  return (
    <>
      {isAuthenticated() ? (
        <Route {...rest} render={({ location }) => children} />
      ) : (
        <Route
          //  {...rest}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: routes.login,
                state: { from: location },
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default withRouter(PrivateRoute);

import React from "react";
import {
  Typography,
  List,
  Popover,
  makeStyles,
  IconButton,
  Button,
  TextField,
} from "@material-ui/core";
import Group from "./group";
import AddIcon from "@material-ui/icons/Add";
import Rules from "./rules";
import { withAllContexts } from "../HOCs";
import { actions } from "atp-rule-binder";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../config";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "grid",
    gridTemplateColumns: "260px 1fr",
  },
  groups: {
    padding: "16px",
    backgroundColor: "white",
    borderRight: `1px solid ${theme.palette.grey["200"]}`,
  },
  createGroup: {
    display: "grid",
    gridGap: "6px",
  },
  textField: {
    paddingTop: theme.spacing(0),
    width: "260px",
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  inputField: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: "14px",
  },
  groupNameCreate: {
    padding: theme.spacing(2),
    boxShadow: "0px 0px 10px 3px rgba(239,239,239,0.5)",
    borderRadius: "5px",
    border: `1px solid ${theme.palette.grey["200"]}`,
  },
  addButton: {
    color: theme.palette.grey["400"],
    border: `1px solid ${theme.palette.grey["200"]}`,
    borderRadius: "4px",
  },
}));

function Groups(props) {
  const disPatch = useDispatch();

  const classes = useStyles();
  const groupList = useSelector(
    (state) => state.GroupsAndRulesSlice.group_list.data
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({ name: "", groupId: null });

  React.useEffect(() => {
    const getMaster = async () => {
      let data = await disPatch(actions.READ_GROUPS());
      let groupId = data?.payload?.data?.[0]?.value ?? null;
      props.groupsData.setGroups({
        ...props.groupsData,
        groupId: groupId,
      });
      // setState({ name: "", groupId: groupId })
    };
    getMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // let e = disPatch(actions.clientandproject_detail());
    (async function () {
      const Tooldetails = await disPatch(
        actions.TOOL_DETAILS({
          db: config.adminconsoledb,
          id: localStorage.getItem("metaDataId"),
        })
      );

      const projectdetails = await disPatch(
        actions.PROJECT_DETAILS({
          db: config.adminconsoledb,
          id: Tooldetails?.payload?.data?.[0]?.projectid,
        })
      );
      localStorage.setItem(
        "dbname",
        projectdetails?.payload?.data?.[0]?.projectdbname
      );
      localStorage.setItem(
        "clientid",
        Tooldetails?.payload?.data?.[0]?.clientid
      );
      localStorage.setItem(
        "projectid",
        Tooldetails?.payload?.data?.[0]?.projectid
      );
      console.log("Tooldetails", Tooldetails, projectdetails);
    })();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setState({ ...state, name: "" });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setState({ ...state, name: "" });
  };

  const saveGroup = async () => {
    handleClose();
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Createing new group",
    });
    await disPatch(actions.UPSERT_GROUP({ name: state.name }));
    await disPatch(actions.READ_GROUPS());
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.main}>
      <div className={classes.groups}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ fontSize: "18px" }} color="initial">
            Groups
          </Typography>
          <div>
            <IconButton
              className={classes.addButton}
              onClick={handleClick}
              size="small"
              color="default"
            >
              <AddIcon fontSize={"small"} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              // onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              elevation={0}
              PaperProps={{
                className: classes.groupNameCreate,
              }}
            >
              <div className={classes.createGroup}>
                <Typography variant="subtitle2" className={classes.typography}>
                  Create Group
                </Typography>
                <div>
                  <TextField
                    placeholder="Group Name"
                    type="text"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    lassName={classes.textField}
                    InputProps={{ classes: { input: classes.inputField } }}
                    onChange={(e) =>
                      setState({ ...state, name: e.target.value })
                    }
                    value={state.name}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button size="small" onClick={handleClose}>
                    {" "}
                    Close{" "}
                  </Button>
                  <Button color="primary" size="small" onClick={saveGroup}>
                    {" "}
                    Create{" "}
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <div>
          <List style={{ display: "grid", gridGap: "4px", margin: "16px 0px" }}>
            {groupList?.length > 0 &&
              groupList.map((group) => (
                <Group key={group.name} group={group} />
              ))}
          </List>
        </div>
      </div>
      <Rules groupId={state.groupId} />
    </div>
  );
}

export default withAllContexts(Groups);
